import React from "react"
import { Col, Form, Row } from "react-bootstrap"

import { useFormContext } from "react-hook-form"
import Select from "react-select"
import {
	AddressSchemaType,
	useGetDoctorAddressesMutate,
} from "../../../services/CelebDoctorAddress.Service"
import { PatientSchemaType } from "../../../services/CelebPatient.Service"
import { useDoctorListQuery, useGetAllSubUsers } from "../../../services/CelebUser.Service"
import { usePatientDialogStore } from "../../../store/patientDailogStore"
import { userSessionStore } from "../../../store/userSessionStore"
import { PatientEthenicities } from "../../../utils/appConstants"
import DatePicker from "../../form/datePicker"
import { CheckAdmin } from "../../permissions/CheckPermision"
import PatientInputForm, { PatientOtherForm, PatientSelectForm } from "./PatientFormFields"
const PatientInfoTab = () => {
	return (
		<React.Suspense>
			<PatientInfoImpl />
		</React.Suspense>
	)
}

const PatientInfoImpl = () => {
	const methods = useFormContext<PatientSchemaType>()
	const { mutateAsync: getDoctorAddressById } = useGetDoctorAddressesMutate()
	const { setAllOffices, allOffices, doctorList, customErrors } = usePatientDialogStore()
	const sessionUser = userSessionStore( ( state: any ) => state.user )
	const { data: subUsers } = useGetAllSubUsers()
	useDoctorListQuery()
	React.useEffect( () => {
		if ( sessionUser.isAdmin == false ) {
			getDoctorAddress( { label: "", value: sessionUser.id } )
		}
	}, [] )

	const getDoctorAddress = async ( e: { label: string; value: string } ) => {
		const data = await getDoctorAddressById( e.value )
		methods.setValue( "assignedDoctor", e.value )
		const doctorDataSet = methods.watch( "doctorsWithAccess" )
		doctorDataSet == undefined && methods.setValue( "doctorsWithAccess", [ e.value ] )
		setAllOffices( data )
	}

	const handleVisibilityChange = ( selectedOptions: any ) => {
		const dataSet = methods.watch( "treatmentPlan.doctorsWithAccess" )
		const selectedDoctor = selectedOptions.map( ( x: any ) => x.value )
		if ( dataSet != undefined ) {
			methods.setValue( "treatmentPlan.doctorsWithAccess", [ ...selectedDoctor ] )
		} else {
			methods.setValue( "treatmentPlan.doctorsWithAccess", [ ...selectedDoctor ] )
		}
	}

	return (
		<React.Fragment>
			<Form onSubmit={methods.handleSubmit( ( x ) => console.warn( x ) )}>
				<Col>
					<Row className="mt-4">
						<Col>
							<PatientInputForm name="firstName" autoFocus required label="First Name" />
						</Col>
						<Col>
							<PatientInputForm name="lastName" required label="Last Name" />
						</Col>
						<Col>
							<PatientInputForm name="phone" label="Phone Number" type="number" />
						</Col>
					</Row>

					<Row className="mt-4">
						<Col>
							<PatientSelectForm name="gender" required label="Gender">
								<option value={""}>Select Gender</option>
								<option value={"male"}>Male</option>
								<option value={"female"}>Female</option>
								<option value={"other"}>Other</option>
							</PatientSelectForm>
						</Col>
						<Col>
							<DatePicker label="Date of birth" methods={methods} name="dob" />
						</Col>
						<Col>
							<PatientSelectForm name="ethenicity" label="Ethnicity">
								<option>Select Ethnicity</option>
								{Object.keys( PatientEthenicities ).map( ( ethenicity ) => {
									return (
										<option key={ethenicity} value={ethenicity}>
											{PatientEthenicities[ ethenicity ]}
										</option>
									)
								} )}
							</PatientSelectForm>
						</Col>
					</Row>
					<div className="d-flex w-100 gap-4">
						<div className="w-100">
							<PatientInputForm name="email" label="Email" type="email" />
							<p className="text-danger">{customErrors.email}</p>
						</div>

						<CheckAdmin>
							<div className="w-100">
								<PatientOtherForm name="assignedDoctor" label="Doctor to Assign" required>
									<Select
										onChange={( e: any ) => {
											getDoctorAddress( e )
										}}
										options={doctorList}
									/>
								</PatientOtherForm>
							</div>
						</CheckAdmin>
						<div className="w-100">
							<PatientSelectForm
								name="selectedOffice"
								required
								label="Select Office"
								placeholder="Please Select Office"
							>
								<option value={""}>Select Office</option>
								{allOffices.map( ( office: AddressSchemaType ) => {
									return <option value={office.id}>{office.name}</option>
								} )}
							</PatientSelectForm>
						</div>
					</div>
					{subUsers.data.length >= 1 && (
						<div className="w-50">
							<Form.Group>
								<Form.Label>Visibility</Form.Label>
								<Select
									isMulti
									options={subUsers?.data?.map( ( subUser: any ) => ( {
										value: subUser.id,
										label: `${subUser.firstName} ${subUser.lastName}`,
									} ) )}
									onChange={handleVisibilityChange}
									value={methods.watch( "treatmentPlan.doctorsWithAccess" )?.map( ( x ) => {
										if ( subUsers.data.find( ( z: any ) => z.id == x )?.firstName ) {
											return {
												value: x,
												label: `${subUsers.data.find( ( z: any ) => z.id == x )?.firstName}`,
											}
										}
									} )}
									isSearchable
									isClearable
								/>
							</Form.Group>
						</div>
					)}
				</Col>
			</Form>
		</React.Fragment>
	)
}

export default PatientInfoTab
