import { faCloudUpload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import JSZip from "jszip"
import React, { useEffect, useState } from "react"
import { CloseButton, Col, Form, Row } from "react-bootstrap"
import { useDropzone } from "react-dropzone"
import { usePatientDialogStore } from "../../../store/patientDailogStore"

const PatientCbctTab = () => {
	const [ cbct, setCbct ] = useState<"noCbct" | "cbct">( "noCbct" )
	const { cbctDocumentJson } = usePatientDialogStore()

	useEffect( () => {
		setCbct( cbctDocumentJson.patient_cbct ? "cbct" : "noCbct" )
	}, [ cbctDocumentJson.patient_cbct ] )
	return (
		<React.Fragment>
			<div className="d-flex gap-5">
				<Form.Check
					reverse
					type="radio"
					checked={cbct === "noCbct"}
					label="No CBCT"
					name="cbctSelection"
					onChange={() => setCbct( "noCbct" )}
				/>
				<Form.Check
					reverse
					type="radio"
					label="Upload CBCT"
					checked={cbct === "cbct"}
					name="cbctSelection"
					onChange={() => setCbct( "cbct" )}
				/>
			</div>
			{cbct === "cbct" && <CbctUpload />}
		</React.Fragment>
	)
}

const CbctUpload = () => {
	const { cbctDocumentJson, setCbctDocument } = usePatientDialogStore()
	const [ filesList, setFilesList ] = useState<File[]>( [] )
	const [ uploadType, setUploadType ] = useState<boolean>( false )
	const { getRootProps, getInputProps, open } = useDropzone( {
		noClick: true,
		noKeyboard: true,
		onDrop: ( acceptedFiles ) => setFilesList( acceptedFiles ),
	} )

	const fileZipSaver = async () => {
		if ( filesList.length === 0 ) return

		const newZip = new JSZip()
		filesList.forEach( ( file ) => {
			newZip.file( file.name, file )
		} )

		try {
			const zipBlob = await newZip.generateAsync( { type: "blob" } )
			const zipFile = new File( [ zipBlob ], "cbct_files.zip", {
				type: "application/zip",
			} )
			setCbctDocument( "patient_cbct", zipFile )
		} catch ( error ) {
			console.error( "Error generating zip file:", error )
		}
	}

	useEffect( () => {
		fileZipSaver()
	}, [ filesList ] )

	const files = filesList.map( ( file ) => <li key={file.name}>{file.name}</li> )

	return (
		<Form>
			<Row className="my-4">
				<Col>
					<div className="cbct-file-name">
						<p>{cbctDocumentJson.patient_cbct ? cbctDocumentJson.patient_cbct.name : ""}</p>
						{cbctDocumentJson.patient_cbct && (
							<CloseButton
								className="cbct-close-btn"
								onClick={() => setCbctDocument( "patient_cbct", null )}
							/>
						)}
					</div>
					<div className="cbct-container">
						<div {...getRootProps( { className: "cbct-dropzone" } )}>
							<input
								{...getInputProps( {
									dir: uploadType ? "" : undefined,
									webkitdirectory: uploadType ? "true" : undefined,
								} )}
							/>
							<FontAwesomeIcon icon={faCloudUpload} className="cbct-icon" />
							<p className="cbct-message">Drag 'n' Drop to Upload CBCT</p>
							<button
								className="btn btn-primary cbct-button mx-2"
								type="button"
								onClickCapture={() => setUploadType( true )}
								onClick={() => {
									setUploadType( true )
									open()
								}}
							>
								Select Folder
							</button>
							<button
								className="btn btn-primary cbct-button"
								type="button"
								onClick={() => {
									setUploadType( false )
									open()
								}}
							>
								Select File
							</button>
						</div>
						<aside>
							<ul className="cbct-file-list">{files}</ul>
						</aside>
					</div>
				</Col>
			</Row>
		</Form>
	)
}

export default PatientCbctTab
