import { faCircleCheck, faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Card } from "react-bootstrap"
import { useSearchParams } from "react-router-dom"
import { useValidateLinkMuation } from "../../services/CelebAuth.Service"

const ResetAuth = ( { children }: { children: React.ReactNode } ) => {
	const [ searchParams ] = useSearchParams()
	const resetLink = searchParams.get( "resetlink" )
	const { data: data, isPending: isPending } = useValidateLinkMuation( resetLink + "" )
	if ( data == "reseted password" ) {
		return MyInvalidatePage( {
			errormessage: "Password successfully set.",
			icon: faCircleCheck,
			iconStyle: { color: "#0cdf56", height: "200px" },
		} )
	} else if ( data === true ) {
		return <>{children}</>
	} else {
		if ( !isPending ) {
			return MyInvalidatePage( {
				errormessage: "Invalid link",
				icon: faCircleExclamation,
				iconStyle: { color: "#f5232d ", height: "200px" },
			} )
		}
	}
}

export default ResetAuth

const MyInvalidatePage = ( {
	errormessage,
	icon,
	iconStyle,
}: {
	errormessage: string
	icon: any
	iconStyle: {}
} ) => {
	return (
		<div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
			<Card className="p-4" style={{ height: "70%", width: "70%" }}>
				<Card.Body>
					<FontAwesomeIcon className="mb-5" icon={icon} size="xl" style={iconStyle} />
					<Card.Text className="text-center">
						<h1 className="text-center">
							<b>{errormessage}</b>
						</h1>
						<a href="/" className="btn btn-primary mt-2 text-center">
							Home
						</a>
					</Card.Text>
				</Card.Body>
			</Card>
		</div>
	)
}
