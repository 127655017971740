import {
	QueryClient,
	QueryClientProvider as TanStackQueryClientProvider,
} from "@tanstack/react-query"

export const queryClient = new QueryClient( {
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: "always",
			refetchOnMount: "always",
			retry: false,
			refetchInterval: 60000 * 5,
		},
	},
} )

export const QueryClientProvider: React.FC<React.PropsWithChildren> = ( props ) => (
	<TanStackQueryClientProvider client={queryClient} {...props} />
)
