import { ProgressBar, Toast } from "react-bootstrap"
import { userSessionStore } from "../../store/userSessionStore"

export const ToastPopup = () => {
	const { showToast, setShowToast, progressLoaderObj } = userSessionStore()

	return (
		<Toast
			show={showToast.visible}
			style={{
				position: "fixed",
				bottom: "1rem",
				right: "1rem",
				zIndex: 1000,
			}}
			autohide={true}
			delay={showToast.timer ?? 2000}
			onClose={() => setShowToast( "visible", false )}
		>
			<Toast.Header className="gap-1">
				<img
					src="/celebLogo.png"
					className="rounded me-2 "
					style={{ width: "35px", height: "35px" }}
					alt=""
				/>
				<strong className="me-auto">
					{showToast.message +
						" " +
						( showToast?.uploadPercentage ? showToast?.uploadPercentage + "%" : "" )}
				</strong>
				<ProgressBar max={progressLoaderObj.max} animated now={progressLoaderObj.now} />
			</Toast.Header>
		</Toast>
	)
}
