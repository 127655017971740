import React, { useEffect } from "react"
import { CloseButton, Form } from "react-bootstrap"
import addbutton from "../../../assets/img/add-button.svg"
import { PatientDialogState, usePatientDialogStore } from "../../../store/patientDailogStore"
import { checkPhotosSet } from "../../../utils/utilFunctions"
import { photosLibrary } from "../editPatients-v2/defaultFileShowCase"
import PatientPhotoComposite from "./PatientPhotoComposite"

const PatientPhotosTab = () => {
	return (
		<React.Suspense>
			<PatientPhotosTabImpl />
		</React.Suspense>
	)
}
const PatientPhotosTabImpl = () => {
	const { individualPhoto } = usePatientDialogStore()
	const [ isPhotoCondition, setIsPhotoCondition ] = React.useState<"composite" | "individual">(
		"composite",
	)

	useEffect( () => {
		setIsPhotoCondition( checkPhotosSet( individualPhoto ) ? "composite" : "individual" )
	}, [ individualPhoto ] )

	return (
		<React.Fragment>
			<div className="d-flex gap-5">
				<Form.Check
					reverse
					type="radio"
					checked={isPhotoCondition === "individual"}
					label="Individual"
					name="celebrace"
					onChange={() => setIsPhotoCondition( "individual" )}
				/>
				<Form.Check
					reverse
					type="radio"
					checked={isPhotoCondition === "composite"}
					label="Composite"
					name="celebrace"
					onChange={() => setIsPhotoCondition( "composite" )}
				/>
			</div>
			{isPhotoCondition == "composite" ? <PatientPhotoComposite /> : <ImageGrid />}
		</React.Fragment>
	)
}

export const ImageGrid = () => {
	return (
		<section className="d-flex justify-content-center">
			<div>
				<span className="d-flex gap-3 my-2">
					<CardComponent property="patient_photo_face_side" />
					<CardComponent property="patient_photo_face_front" />
					<CardComponent property="patient_photo_face_front_teeth" />
				</span>
				<span className="d-flex gap-3 my-2">
					<CardComponent property="patient_photo_jaw_upper" />
					<div className="card text-bg-secondary" style={{ width: "10rem" }}>
						<img
							alt=""
							src=""
							className="img-fluid"
							style={{ height: "-webkit-fill-available", objectFit: "contain" }}
						/>
					</div>
					<CardComponent property="patient_photo_jaw_lower" />
				</span>
				<span className="d-flex gap-3 my-2">
					<CardComponent property="patient_photo_jaw_right" />
					<CardComponent property="patient_photo_jaw_front" />
					<CardComponent property="patient_photo_jaw_left" />
				</span>
			</div>
		</section>
	)
}

const CardComponent = ( { property }: { property: keyof PatientDialogState["individualPhoto"] } ) => {
	const imageRef = React.useRef<HTMLInputElement | null>( null )
	const { individualPhoto, setIndividualPhoto } = usePatientDialogStore()
	const fileImageSaver = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		e.preventDefault()
		if ( e.currentTarget.files ) {
			setIndividualPhoto( property, e.currentTarget.files[ 0 ] )
		}
	}
	const img = individualPhoto[ property ]
	const check = photosLibrary[ property ]
	return (
		<div>
			<span className="d-flex" style={{ height: "10rem", width: "10rem" }}>
				<div
					className="card text-bg-secondary"
					onClick={() => {
						check.length >= 3 ? imageRef.current?.click() : ""
					}}
					style={{ width: "10rem" }}
				>
					<img
						alt=""
						src={img ? URL.createObjectURL( img ) : photosLibrary[ property ]}
						className="img-fluid"
						style={{ height: "-webkit-fill-available", objectFit: "contain" }}
					/>
					{img && (
						<CloseButton
							onClick={( e ) => {
								e.preventDefault()
								e.stopPropagation()
								setIndividualPhoto( property, null )
							}}
							style={{
								position: "absolute",
								top: "0px",
								right: "0px",
								backgroundColor: "#E3E0F0",
								borderRadius: "50%",
								zIndex: 100,
							}}
						/>
					)}
					<Addbutton />
				</div>
			</span>
			<input type="file" accept="image/*" ref={imageRef} hidden onChange={fileImageSaver} />
		</div>
	)
}

export const Addbutton = () => {
	return (
		<div
			className="card-img-overlay"
			style={{
				display: "flex",
				justifyContent: "end",
				height: "-webkit-fill-available",
				alignItems: "end",
				padding: "6px",
			}}
		>
			<img src={addbutton} alt="" />
		</div>
	)
}

export default PatientPhotosTab
