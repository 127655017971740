import { Elements, ElementsConsumer } from "@stripe/react-stripe-js"
import React from "react"
import { Card, Modal, Nav, Navbar } from "react-bootstrap"

import { useSetupIntentQuery } from "../../../services/CelebPayments.Service"
import { useProfileDialogStore } from "../../../store/profileDialogStore"
import { StripeSharedKey } from "../../../utils/appConstants"
import PaymentMethodForm from "./PaymentMethodCardForm.tab"
import useStripeLoader from "../../../hooks/stripe/useStripeLoader"

const PaymentMethodAddDialogDialog = () => {
	const profile = useProfileDialogStore()
	const {
		data: { secretKey },
	} = useSetupIntentQuery()

	const loader = "auto"

	const stripe = useStripeLoader( StripeSharedKey )

	return (
		<React.Suspense>
			<Modal
				show={profile.paymentCreateOpen}
				onHide={() => profile.setPaymentCreateOpen( false )}
				size="xl"
				backdrop="static"
				backdropClassName="blur"
			>
				<Navbar style={{ backgroundColor: "#d9d9d9" }} as={Modal.Header} closeButton>
					<Nav>
						<Nav.Link className="nav-link-opposite mt-1" active={true}>
							Add payment method
						</Nav.Link>
					</Nav>
				</Navbar>

				<Modal.Body>
					<Card>
						<Card.Body>
							{secretKey && stripe && (
								<Elements
									options={{
										clientSecret: secretKey,
										appearance: {
											theme: "stripe",
										},
										loader,
									}}
									stripe={stripe}
								>
									<ElementsConsumer>
										{() => <PaymentMethodForm clientSecret={secretKey} />}
									</ElementsConsumer>
								</Elements>
							)}
						</Card.Body>
					</Card>
				</Modal.Body>
			</Modal>
		</React.Suspense>
	)
}

export default PaymentMethodAddDialogDialog
