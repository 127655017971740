import { Button } from "react-bootstrap"
import Moment from "react-moment"
import { useSearchParams } from "react-router-dom"
import { useServicePricingsfetchDetails } from "../../../services/CelebService.Service"
import { useSidebarStore } from "../../../store/sideBarStore"

const Financials = () => {
	useSidebarStore()
	const sideBarData = useSidebarStore()
	const [ searchParams ] = useSearchParams()
	const newParams = new URLSearchParams( searchParams )
	const treatmentId = newParams.get( "PatientId" )
	const { data: data } = useServicePricingsfetchDetails( treatmentId! )
	return (
		<div className="sideBarPatientInfo">
			<div>
				<span className="label">Paid :</span>{" "}
				<span className="label-data">$ {data?.amount.toFixed( 2 ) ?? " "}</span>
			</div>
			<div>
				<span className="label">Approval Date :</span>{" "}
				<span className="label-data">
					{data?.createdAt && (
						<Moment format="MM/DD/YYYY" utc>
							{data?.createdAt}
						</Moment>
					)}
				</span>
			</div>
			<div>
				<span className="label">Receipt :</span>{" "}
				<span className="label-data">
					<Button
						className="mx-3"
						href={
							"/receipt/" +
							treatmentId +
							"?serviceId=" +
							sideBarData?.sideBarPatientData.PatientData.serviceId
						}
						disabled={!data}
						// onClick={() => viewReceiptHandler()}
						size="sm"
						target="_blank"
					>
						View
					</Button>
				</span>
			</div>
		</div>
	)
}

export default Financials
