import { zodResolver } from "@hookform/resolvers/zod"
import React, { Suspense } from "react"
import { Alert, Col, Form, Modal, Nav, Navbar, Row } from "react-bootstrap"
import { FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form"
import Select from "react-select"
import { OverlaySpinner } from "../../../assets/styles/components/loader/loadingOverlay"
import "../../../assets/styles/pages/Theme.form.css"
import { queryClient } from "../../../providers/QueryProvider"
import { ResponseTypeRole, useListRolesQuery } from "../../../services/CelebRole.Service"
import {
	getSalesRepresentativesApi,
	SalesClientsAssociactedToDoctor,
	useGetSalesClientsAssociactedToDoctor,
} from "../../../services/CelebSalesRepresentative.service"
import {
	useListServicesQuery,
	useListUserServicePricesQuery,
	UserServiceSchemaType,
} from "../../../services/CelebService.Service"
import {
	EditUserSchema,
	EditUserType,
	useEditUserMutation,
	useListUsersQuery,
	UserType,
} from "../../../services/CelebUser.Service"
import { useAccountsDialogStore } from "../../../store/accountsDialogStore"
import { userSessionStore } from "../../../store/userSessionStore"
import { sortForReactSelect } from "../../utlity/GenricFunctions"
import {
	BoundCheckbox,
	BoundControl,
	BoundControlPricing,
	BoundSelect,
	DoctorSpecificInput,
	SelectOption,
	Submit,
} from "./AccountFormFields"

export const AccountsEditDialog = () => {
	const editOpen = useAccountsDialogStore( ( state ) => state.editOpen )
	const setEditOpen = useAccountsDialogStore( ( state ) => state.setEditOpen )
	const sessionStore = userSessionStore()
	const {
		data: { data: users },
	} = useListUsersQuery()
	const user = users.find( ( u: any ) => u.id === editOpen )
	const onClose = () => {
		setEditOpen( null )
		sessionStore.setShowAlert( "visible", false )
		queryClient.invalidateQueries()
	}
	if ( !user ) {
		return null
	}
	return (
		<Modal
			show={typeof editOpen === "string"}
			onHide={() => {
				onClose()
			}}
			size="xl"
			backdrop="static"
			style={{ height: "100vh", backdropFilter: "blur(10px)" }}
		>
			<AccountsAddDialogImpl user={user} />
		</Modal>
	)
}

const AccountsAddDialogImpl: React.FC<AccountsAddDialogImplProps> = ( { user } ) => {
	const sessionStore = userSessionStore()
	const {
		data: { data: users },
	} = useListUsersQuery()

	const {
		data: { data: listSalesRepresentatives },
	} = getSalesRepresentativesApi( { role: "sales" } )

	React.useEffect( () => {
		getSaleRep()
	}, [] )

	const { mutate: getSaleRep, data: selectedSalesRepresentatives } =
		useGetSalesClientsAssociactedToDoctor( user?.id )

	const docSalesRep =
		selectedSalesRepresentatives &&
		selectedSalesRepresentatives?.map( ( e: SalesClientsAssociactedToDoctor ) => {
			return {
				value: e.salesClientSalesUser.id,
				label: `${e.salesClientSalesUser.firstName} ${e.salesClientSalesUser.lastName}`,
			}
		} )

	const { data } = useListUserServicePricesQuery( user.id )
	const methods = useForm<EditUserType>( {
		resolver: zodResolver( EditUserSchema ),
		defaultValues: {
			id: user.id,
			firstName: user.firstName,
			lastName: user.lastName,
			email: user.email,
			contactNumber: user.contactNumber,
			roleId: user.roleId,
			parentAccountId: user.parentAccountId ?? undefined,
			status: user.status,
			salesRepId: docSalesRep,
			servicePricings: data.map( ( p: UserServiceSchemaType ) => ( {
				serviceId: p.serviceId,
				priceBoth: p.priceBoth,
				priceLower: p.priceLower,
				priceUpper: p.priceUpper,
			} ) ),
		},
	} )

	const {
		data: { data: roles },
	} = useListRolesQuery()
	const { mutateAsync: editUserAsync } = useEditUserMutation()

	const onSubmit = async ( value: EditUserType ) => {
		const response = await editUserAsync( value )
		if ( response.data.status === 400 ) {
			sessionStore.setShowAlertJson( response.data )
			return sessionStore.setShowAlert( "visible", true )
		}
		sessionStore.setShowAlert( "visible", false )
		queryClient.invalidateQueries()
		useAccountsDialogStore.getState().setEditOpen( null )
	}

	const salesRepresentativesOptions = sortForReactSelect(
		listSalesRepresentatives,
		"id",
		"firstName",
		"lastName",
	)

	const roleOptions = roles.map(
		( role: ResponseTypeRole ) => ( { id: role.id, label: role.name } ) as SelectOption,
	)

	const parentUserOptions = users.reduce( ( acc: SelectOption[], userParam: any ) => {
		if ( userParam.id !== methods.watch( "id" ) ) {
			acc.push( {
				id: userParam.id,
				label: `${userParam.firstName} ${userParam.lastName}`,
				role: `${userParam.role.name}`,
			} )
		}
		return acc
	}, [] )

	const onSalesRepChange = ( event: any ) => {
		const id = event.map( ( e: { value: string } ) => e.value )
		methods.setValue( "salesRepId", [ ...id ] )
	}

	return (
		<React.Fragment>
			<Navbar style={{ backgroundColor: "#d9d9d9" }} as={Modal.Header} closeButton>
				<Nav className="w-100" style={{ marginTop: "5px" }}>
					<Nav.Link className="nav-link-opposite active">Edit Account</Nav.Link>
				</Nav>
			</Navbar>
			<FormProvider {...methods}>
				<Form onSubmit={methods.handleSubmit( onSubmit )}>
					<Modal.Body>
						<Alert variant="danger" show={sessionStore.showAlert.visible}>
							{sessionStore.showAlert.message}
						</Alert>
						<Row>
							<Col>
								<BoundControl
									required
									name="firstName"
									placeholder="First Name"
									label="First Name"
								/>
							</Col>
							<Col>
								<BoundControl required name="lastName" placeholder="Last Name" label="Last Name" />
							</Col>
							<Col className="text-end">
								<Submit />
							</Col>
						</Row>
						<br />
						<Row>
							<Col>
								<BoundControl required name="email" placeholder="Email" label="Email" />
							</Col>
							<Col>
								<BoundControl
									type="number"
									required
									name="contactNumber"
									placeholder="Phone Number"
									label="Contact number"
								/>
							</Col>
							<Col />
						</Row>
						<br />
						<Row>
							<Col>
								<BoundSelect
									label="Select user type"
									name="roleId"
									required
									options={roleOptions}
									placeholder="Select Role"
								/>
							</Col>
							<DoctorSpecificInput>
								<Col>
									<span>
										<Form.Label>Sales Representative</Form.Label>
										<span className="d-flex" style={{ width: "100%" }}></span>
										<Suspense key={docSalesRep}>
											<Select
												options={salesRepresentativesOptions}
												isSearchable={true}
												isClearable={true}
												isMulti
												defaultValue={docSalesRep}
												onChange={( event ) => {
													onSalesRepChange( event )
												}}
											/>
										</Suspense>
									</span>
								</Col>
								<Col>
									<BoundSelect
										label="Select parent user"
										name="parentAccountId"
										options={parentUserOptions}
										placeholder="Add Parent User if Applicable"
									/>
								</Col>
							</DoctorSpecificInput>
							<Col>
								<BoundCheckbox className="ms-4 mt-4" label="Make account active" name="status" />
							</Col>
						</Row>
						<br />
						<DoctorSpecificInput>
							<Suspense fallback={<OverlaySpinner />}>
								<ServicePricing />
							</Suspense>
						</DoctorSpecificInput>
					</Modal.Body>
				</Form>
			</FormProvider>
		</React.Fragment>
	)
}

type AccountsAddDialogImplProps = { user: UserType }

export default AccountsEditDialog

const ServicePricing = () => {
	const {
		data: { services },
	} = useListServicesQuery()
	const { control } = useFormContext<EditUserType>()
	const arrayMethods = useFieldArray( { name: "servicePricings", control } )

	const handleServiceSelection: React.ChangeEventHandler<HTMLSelectElement> = ( e ) => {
		const selectedService = services.find( ( service ) => service.id === e.target.value )

		if ( !selectedService ) {
			throw new Error( "Unhandled: Non existent service selected" )
		}

		arrayMethods.append( {
			serviceId: selectedService?.id,
		} )
	}

	return (
		<React.Fragment>
			<Row className="mt-4 mb-4">
				<Col md="4">
					<Form.Group>
						<Form.Label>Set Pricing for Services</Form.Label>
						<Form.Select onChange={handleServiceSelection} value="">
							<option value="" disabled>
								Select service
							</option>
							{Array.isArray( services ) &&
								services
									.filter( ( s ) => !arrayMethods.fields.find( ( f ) => f.serviceId === s.id ) )
									.map( ( service ) => (
										<option value={service.id} key={service.id}>
											{service.name}
										</option>
									) )}
						</Form.Select>
					</Form.Group>
				</Col>
			</Row>
			{arrayMethods.fields.length > 0 ? (
				<Row className="mb-2">
					<Col></Col>
					<Col>Upper Jaw price</Col>
					<Col>Lower Jaw price</Col>
					<Col>Both Jaw price</Col>
				</Row>
			) : null}
			{arrayMethods.fields.map( ( item, index ) => {
				const current = services.find( ( service ) => service.id === item.serviceId )
				if ( !current ) {
					return null
				}
				return (
					<Row key={item.id}>
						<Col>{current.name}</Col>
						<Col>
							<BoundControlPricing
								name={`servicePricings.${index}.priceUpper`}
								placeholder="Upper jaw price"
							/>
						</Col>
						<Col>
							<BoundControlPricing
								name={`servicePricings.${index}.priceLower`}
								placeholder="Lower jaw price"
							/>
						</Col>
						<Col>
							<BoundControlPricing
								name={`servicePricings.${index}.priceBoth`}
								placeholder="Both jaw price"
							/>
						</Col>
					</Row>
				)
			} )}
		</React.Fragment>
	)
}
