import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod"
import React, { useEffect } from "react"
import { Card, Container, Form, InputGroup } from "react-bootstrap"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import "../../assets/styles/pages/login.page.css"
import { ToastPopup } from "../../components/toast/Toast"
import {
	LoginRequest,
	LoginRequestSchema,
	useLoginMutation,
} from "../../services/CelebAuth.Service"
import { useLoginPageStore } from "../../store/loginStore"
import { userSessionStore } from "../../store/userSessionStore"
import LoginForgotCredentials from "./forgotPasswordModal"

const Login = () => {
	const methods = useForm<LoginRequest>( { resolver: zodResolver( LoginRequestSchema ), mode: "all" } )
	const { setSession } = userSessionStore()
	useEffect( () => {
		checklogin()
		window.addEventListener( "storage", function () {
			window.location.reload()
		} )
	}, [] )

	const checklogin = () => {
		const UserCheck: string | null = localStorage.getItem( "user" )
		const Usertoken: string | null = localStorage.getItem( "token" )
		const parseduserCheck = UserCheck ? JSON.parse( UserCheck ) : null
		if ( parseduserCheck == null || Usertoken == null ) {
			return null
		}
		if ( parseduserCheck.isAdmin === true && window.location.pathname === "/login" ) {
			window.location.href = "/managment"
		}
		if ( parseduserCheck.isDoctor === true && window.location.pathname === "/login" ) {
			window.location.href = "/doctor"
		}
		if ( parseduserCheck.specialRole === "none" && window.location.pathname === "/login" ) {
			window.location.href = "/operator"
		}
		setSession( parseduserCheck )
	}

	return (
		<FormProvider {...methods}>
			<LoginImp />
		</FormProvider>
	)
}

const LoginImp = () => {
	const {
		register,
		handleSubmit,
		getValues,
		control,
		formState: { errors },
	} = useFormContext<LoginRequest>()
	const [ showPassword, setshowPassword ] = React.useState( false )
	const { setSession, setShowToastJson } = userSessionStore()
	const { setForgotPasswordDialog, forgotPasswordDialog } = useLoginPageStore()
	const { mutateAsync: login } = useLoginMutation()

	const onSubmit = async ( value: LoginRequest ) => {
		try {
			const response = await login( value )
			if ( response ) {
				localStorage.setItem( "user", JSON.stringify( response.user ) )
				localStorage.setItem( "token", response.token )
				setSession( response.user )
				if ( response.user.specialRole === "admin" ) {
					window.location.href = "/managment?status=active"
				} else if ( response.user.specialRole === "doctor" ) {
					window.location.href = "/doctor?status=active"
				} else if ( response.user.specialRole === "none" ) {
					window.location.href = "/operator?status=active"
				} else if ( response.user.specialRole === "sales" ) {
					window.location.href = "/sales?status=active"
				}
			}
		} catch ( e ) {
			setShowToastJson( {
				message: "Incorrect ID or password",
				status: "Danger",
				visible: true,
			} )
			control.setError( "root", { message: "Incorrect ID or password" } )
		}
	}

	return (
		<Container
			style={{
				width: "100%",
				height: "98dvh",
				display: "grid",
				justifyContent: "center",
				alignItems: "center",
				alignContent: "center",
			}}
		>
			<Card className="card loginFormContainer" style={{ width: "25rem", minHeight: "17rem" }}>
				<Card.Header className="card-header">
					<span className="d-flex w-100 text-center justify-content-center gap-2">
						<img src="/celebLogo.png" style={{ height: "50px", width: "50px" }}></img>
						<h3 className="text-center" style={{ display: "grid", alignItems: "end" }}>
							Celebrace Login
						</h3>
					</span>
				</Card.Header>
				{errors && (
					<div className="text-danger" style={{ display: "flex", justifyContent: "center" }}>
						{errors.root?.message}
					</div>
				)}
				<Form
					className="card-body loginForm"
					style={{ width: "100%" }}
					onSubmit={handleSubmit( onSubmit )}
				>
					<Form.Control
						{...register( "email", { required: true } )}
						type="email"
						isInvalid={errors.email?.message ? true : false}
						placeholder="Email"
						autoComplete="Email"
						autoFocus
					/>
					<InputGroup className="my-2">
						<Form.Control
							isInvalid={errors.password?.message ? true : false}
							{...register( "password", { required: true } )}
							type={showPassword ? "text" : "password"}
							placeholder="Password"
						/>
						<InputGroup.Text>
							<FontAwesomeIcon
								onClick={( e ) => {
									e.preventDefault()
									setshowPassword( !showPassword )
								}}
								icon={showPassword ? faEyeSlash : faEye}
							/>
						</InputGroup.Text>
					</InputGroup>
					<span className="d-flex justify-content-between w-100">
						<button className="btn btn-primary" type="submit">
							Login
						</button>
						<button
							type="button"
							className="btn float-end text-black"
							onClick={() => setForgotPasswordDialog( true, getValues( "email" ) )}
						>
							Forgot Password
						</button>
					</span>
				</Form>
			</Card>
			{forgotPasswordDialog && <LoginForgotCredentials />}
			<ToastPopup />
		</Container>
	)
}

export default Login
