import { CelebMedicalFileService } from "../services/CelebMedicalFile.Service"

export const formatDateToMMDDYYYY = ( dateString: string | number | Date ) => {
	const date = new Date( dateString )
	const month = String( date.getMonth() + 1 ).padStart( 2, "0" )
	const day = String( date.getDate() ).padStart( 2, "0" )
	const year = date.getFullYear()
	return `${month}-${day}-${year}`
}

export const checkPhotosSet = ( individualPhoto: any ) => {
	return Object.values( individualPhoto ).every( ( photo ) => !photo )
}

export const downloadFile = async ( url: string, filename: string ) => {
	const imageUrl = await CelebMedicalFileService.getInstance()
		.getFileReadUrl( url )
		.then( ( res ) => res.data.data )
		.then( ( res ) => res )

	const response = await fetch( imageUrl )
	const blob = await response.blob()
	const contentType = response.headers.get( "Content-Type" )
	let extensionFromUrl = ""
	if ( !contentType ) {
		const urlExtensionMatch = url.match( /\.(\w+)$/ )
		if ( urlExtensionMatch ) {
			extensionFromUrl = "." + urlExtensionMatch[ 1 ].toLowerCase()
		}
	}
	if ( extensionFromUrl && !filename.toLowerCase().endsWith( extensionFromUrl ) ) {
		filename += extensionFromUrl
	}
	const link = document.createElement( "a" )
	const blobUrl = window.URL.createObjectURL( blob )
	link.href = blobUrl
	link.download = filename
	document.body.appendChild( link )
	link.click()
	document.body.removeChild( link )
	window.URL.revokeObjectURL( blobUrl )
	return true
}

export const handleDownload = ( e: any, url: string, fileName: string ) => {
	e.preventDefault()
	const link = document.createElement( "a" )
	link.href = url
	link.setAttribute( "download", fileName )
	document.body.appendChild( link )
	link.click()
	document.body.removeChild( link )
	return
}
