import React from "react"
import { Outlet } from "react-router"
import { useSearchParams } from "react-router-dom"
import "../../../../assets/styles/components/celebServiceTabs.css"
import "../../../../assets/styles/components/navbar.css"
import "../../../../assets/styles/pages/landing.page.css"
import DashBoardNavbar from "../../../../components/navbars/DashBoardNavbar"
import SubNavBar from "../../../../components/navbars/SubNavBar"
import NavbarNavigationDummy from "../../../../components/navbars/sideNavbar/dummySidebar/navbarNavigation"
import SideNavbar from "../../../../components/navbars/sideNavbar/sideNavbar"

const Dashboard = () => {
	const [ searchParams ] = useSearchParams()
	const patientId = searchParams.get( "PatientId" )
	return (
		<React.Suspense>
			<DashBoardNavbar />
			<main className="d-flex" style={{ height: "90vh" }}>
				<section
					style={{
						width: "-webkit-fill-available",
						border: "5px solid #d9d9d9",
						borderRadius: "12px ",
						overflow: "scroll",
						scrollbarWidth: "none",
					}}
				>
					<SubNavBar />
					<Outlet />
				</section>
				<aside className="w-35" style={{ width: "35%", height: "90vh" }}>
					{patientId ? <SideNavbar /> : <NavbarNavigationDummy />}
				</aside>
			</main>
		</React.Suspense>
	)
}

export default Dashboard
