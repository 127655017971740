import React from "react"
import { loadStripe, Stripe } from "@stripe/stripe-js"

const useStripeLoader = ( StripeSharedKey: string ): Stripe | null => {
	const [ stripe, setStripe ] = React.useState<Stripe | null>( null )

	React.useEffect( () => {
		const loadStripeInstance = async () => {
			try {
				const stripeInstance = await loadStripe( StripeSharedKey )
				setStripe( stripeInstance )
			} catch ( error ) {
				console.error( "Failed to load Stripe:", error )
			}
		}
		loadStripeInstance()
	}, [ StripeSharedKey ] )

	return stripe
}

export default useStripeLoader
