import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { Alert, Button, Col, Form, Modal, Row, Table } from "react-bootstrap"
import { FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import LoadingOverlay from "../../../assets/styles/components/loader/loadingOverlay"
import {
	labelFinderType,
	PreviousRollBackType,
	ReactSelectCreatableCustom,
	ServiceTaskForm,
} from "../../../services/CelebService.Service"
import { useServiceDialogStore } from "../../../store/serviceDialogStore"
import { TaskCategory } from "../../../utils/appConstants"
import { EditTrash } from "../../utlity/EditTrash"
import {
	BoundSelect,
	CreatableSelectEdit,
	ServiceFormTaskInput,
	ServiceFormType,
	ServiceTaskFormType,
} from "./ServiceFormFields"

const ServiceTaskBox = () => {
	const { serviceProviderCategories, serviceConsumerCategories } = useServiceDialogStore()
	const { control, watch, formState } = useFormContext<ServiceFormType>()
	const tasksData = useFieldArray( { name: "tasks", control, keyName: "uid" } )
	const methods = useForm<ServiceTaskFormType>( {
		resolver: zodResolver( ServiceTaskForm ),
		mode: "all",
	} )
	const [ taskOptions, setTaskOptions ] = React.useState<any[]>( [] )
	const [ taskOptionsValue, setTaskOptionsValue ] = React.useState<PreviousRollBackType>( {
		previousTasks: [],
		rollBackToTask: [],
	} )
	const [ editIndex, setEditIndex ] = React.useState<number | null>( null )

	React.useEffect( () => setTaskOptionList(), [ tasksData.fields ] )

	const validateFields = ( value: ServiceTaskFormType ) => {
		const checkValidation = tasksData.fields.find( ( taskObject ) => taskObject.name === value.name )
		if ( checkValidation ) return methods.setError( "name", { message: "please enter unique name" } )
		return true
	}

	const addTask = ( value: ServiceTaskFormType ) => {
		if ( !validateFields( value ) ) {
			return
		}

		try {
			const eachTask = {
				...value,
				previousTasks: taskOptionsValue.previousTasks,
				rollBackToTask: taskOptionsValue.rollBackToTask,
				isNew: true,
				id: crypto.randomUUID(),
			}

			if ( tasksData.fields.length === 0 ) {
				eachTask.paymentStage = true
				eachTask.replaceOrderTask = true
			}

			tasksData.append( eachTask )
			cancelTaskForm()
		} catch ( error ) {
			console.error( "Add Task Error", error )
		}
	}

	const checkAndDelete = ( taskObject: any, index: number ): void => {
		for ( const task of tasksData.fields ) {
			if ( task.previousTasks ) {
				for ( const priorTask of task.previousTasks ) {
					if ( priorTask.label === taskObject.name ) {
						return methods.setError( "previousTasks", {
							message: "Cannot delete task dependent on some another task",
						} )
					}
				}
			}
		}
		tasksData.remove( index )
	}

	const populateTaskToEdit = ( taskToEdit: ServiceTaskFormType, index: number ) => {
		setEditIndex( index )
		const tempObjOptionValue = {
			previousTasks: taskToEdit.previousTasks,
			rollBackToTask: taskToEdit.rollBackToTask,
		}
		setTaskOptionsValue( tempObjOptionValue )
		methods.setValue( "id", taskToEdit.id )
		methods.setValue( "name", taskToEdit.name )
		methods.setValue( "consumerCategoryId", taskToEdit.consumerCategoryId )
		methods.setValue( "providerCategoryId", taskToEdit.providerCategoryId )
		methods.setValue( "writeNote", taskToEdit.writeNote )
		methods.setValue( "uploadFile", taskToEdit.uploadFile )
		methods.setValue( "checkmark", taskToEdit.checkmark )
		methods.setValue( "previousTasks", taskToEdit.previousTasks )
		methods.setValue( "rollBackToTask", taskToEdit.rollBackToTask )
		methods.setValue( "replaceOrderTask", taskToEdit.replaceOrderTask )
		methods.setValue( "paymentStage", taskToEdit.paymentStage )

		if ( taskToEdit.isNew ) {
			methods.setValue( "isNew", taskToEdit.isNew )
		}
		const updatedPriorTasks = taskOptions.filter( ( task ) => task.value !== taskToEdit.id )
		setTaskOptions( updatedPriorTasks )
	}

	const saveEditedTask = (
		event: React.MouseEvent<HTMLButtonElement>,
		value: ServiceTaskFormType,
		index: number,
	) => {
		event.preventDefault()
		const updatedTaskData = {
			...value,
			id: value.id!,
			previousTasks: taskOptionsValue.previousTasks,
			rollBackToTask: taskOptionsValue.rollBackToTask,
		}

		tasksData.update( index, updatedTaskData )
		cancelTaskForm()
	}

	const cancelTaskForm = () => {
		setTaskOptionsValue( {
			previousTasks: [],
			rollBackToTask: [],
		} )
		setEditIndex( null )
		methods.reset()
	}

	const setTaskOptionList = () => {
		const taskOptionsList = tasksData.fields.map( ( task ) => {
			return {
				value: task.id,
				label: task.name,
			}
		} )
		setTaskOptions( taskOptionsList )
	}

	const setPaymentRollBack = (
		setPayRoll: "Payment" | "RollBack" | "approvalTask",
		index: number,
	) => {
		if ( setPayRoll == "Payment" ) {
			tasksData.fields.map( ( x, taskIndex ) => {
				const taskToSetFalse = { ...x, paymentStage: taskIndex === index }
				tasksData.update( taskIndex, taskToSetFalse )
			} )
		} else if ( setPayRoll == "RollBack" ) {
			tasksData.fields.map( ( x, taskIndex ) => {
				const taskToSetFalse = { ...x, replaceOrderTask: taskIndex === index }
				tasksData.update( taskIndex, taskToSetFalse )
			} )
		} else if ( setPayRoll == "approvalTask" ) {
			tasksData.fields.map( ( x, taskIndex ) => {
				const taskToSetFalse = { ...x, approvalTask: taskIndex === index }
				tasksData.update( taskIndex, taskToSetFalse )
			} )
		}
	}
	const errors = watch( "taskErrors" ) || formState.errors.tasks?.message
	return (
		<React.Suspense fallback={<LoadingOverlay />}>
			<Modal.Body>
				<TaskCategories />
				<hr />
				<FormProvider {...methods}>
					<Form
						onSubmit={methods.handleSubmit( addTask, ( error ) => console.error( "Task Error", error ) )}
					>
						<p className="text-danger">{methods.formState.errors.previousTasks?.message}</p>
						<Row
							className={`pt-2 ${editIndex != null ? "containerEditingTask" : ""} d-flex align-items-center`}
						>
							<Col className="">
								<ServiceFormTaskInput name="name" required label="Task Name" />
							</Col>

							<Col>
								<BoundSelect
									label="Provider Category"
									name="providerCategoryId"
									required
									options={serviceProviderCategories}
									placeholder="Select Category"
								/>
							</Col>

							<Col>
								<BoundSelect
									label="Consumer Category"
									name="consumerCategoryId"
									required
									options={serviceConsumerCategories}
									placeholder="Select Category"
								/>
							</Col>
						</Row>
						<Row
							className={`pt-2 ${editIndex != null ? "containerEditingTask" : ""} d-flex align-items-center`}
						>
							<Col>
								<Form.Check
									type="checkbox"
									className="mt-4"
									label={"Write Notes"}
									{...methods.register( "writeNote" )}
								/>
							</Col>
							<Col>
								<Form.Check
									type="checkbox"
									className="mt-4"
									label={"Upload Files"}
									{...methods.register( "uploadFile" )}
								/>
							</Col>
							<Col>
								<Form.Check
									type="checkbox"
									className="mt-4"
									label={"Checkmark"}
									{...methods.register( "checkmark" )}
								/>
							</Col>
						</Row>
						<Row className={"pt-4 pb-2 " + ( editIndex != null ? "containerEditingTask" : "" )}>
							<Col>
								<Form.Group>
									<Form.Label>Prior tasks</Form.Label>
									<Select
										options={taskOptions}
										isSearchable={true}
										isClearable={true}
										isMulti
										value={taskOptionsValue.previousTasks}
										onChange={( event ) => {
											setTaskOptionsValue( { ...taskOptionsValue, [ "previousTasks" ]: [ ...event ] } )
										}}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group>
									<Form.Label>Reassignment</Form.Label>
									<Select
										options={taskOptions}
										isSearchable={true}
										isClearable={true}
										isMulti
										value={taskOptionsValue.rollBackToTask}
										onChange={( event ) => {
											setTaskOptionsValue( { ...taskOptionsValue, [ "rollBackToTask" ]: [ ...event ] } )
										}}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Col className="text-end">
							{editIndex != null ? (
								<>
									<Button
										size="sm"
										type="button"
										onClick={( e: React.MouseEvent<HTMLButtonElement> ) =>
											saveEditedTask( e, methods.getValues(), editIndex )
										}
									>
										Update Task
									</Button>
									<div className="me-2"></div>
									<Button
										size="sm"
										variant="secondary"
										type="button"
										onClick={cancelTaskForm}
										className="my-2 btn btn-secondary"
									>
										Cancel
									</Button>
								</>
							) : (
								<Button size="sm" type="submit" className="my-2">
									Add Task
								</Button>
							)}
						</Col>
					</Form>
					<Alert hidden={!errors} variant="warning">
						{errors}
					</Alert>
					<Row>
						<Col>
							<Table striped bordered>
								<thead>
									<tr>
										<th>Task</th>
										<th className="text-center">
											Write Notes,
											<br />
											Upload Files,
											<br />
											Checkmark
										</th>
										<th className="text-center">Prior Tasks</th>
										<th className="text-center">Reassign Tasks</th>
										<th className="text-center">Provider Category</th>
										<th className="text-center">Consumer Category</th>
										<th className="text-center">Payment Task</th>
										<th className="text-center">
											Replace
											<br />
											order task
										</th>
										<th className="text-center">Approval Task</th>
										<th className="text-center">Action</th>
									</tr>
								</thead>
								{tasksData.fields.map( ( tasks, index ) => {
									return (
										<tbody key={index}>
											<tr key={index}>
												<td>{tasks.name}</td>
												<td className="text-center">
													{tasks.writeNote ? "Yes" : "No"}, {tasks.uploadFile ? "Yes" : "No"},{" "}
													{tasks.checkmark ? "Yes" : "No"}
												</td>

												<td className="text-center">
													{tasks.previousTasks &&
														tasks.previousTasks.map( ( taskData: any ) => taskData.label ).join( ", " )}
												</td>

												<td className="text-center">
													{tasks.rollBackToTask &&
														tasks.rollBackToTask.map( ( taskData: any ) => taskData.label ).join( ", " )}
												</td>

												<td className="text-center">
													{categorylabelFinder( serviceProviderCategories, tasks.providerCategoryId )}
												</td>
												<td className="text-center">
													{categorylabelFinder( serviceConsumerCategories, tasks.consumerCategoryId )}
												</td>
												<td className="text-center">
													<Form.Check
														type="radio"
														className="text-center mt-2"
														name="payment"
														checked={tasks.paymentStage}
														onChange={() => setPaymentRollBack( "Payment", index )}
													></Form.Check>
												</td>
												<td className="text-center">
													<Form.Check
														type="radio"
														name="replace"
														checked={tasks.replaceOrderTask}
														onChange={() => setPaymentRollBack( "RollBack", index )}
														className="text-center mt-2"
													/>
												</td>
												<td className="text-center">
													<Form.Check
														type="radio"
														name="approvalTask"
														checked={tasks.approvalTask}
														onChange={() => setPaymentRollBack( "approvalTask", index )}
														className="text-center mt-2"
														disabled={tasks.previousTasks.length < 1}
													/>
												</td>
												<td className="text-center">
													<EditTrash
														editIcon={{
															color: "#8faadc",
															icon: faPencil,
															style: { fontSize: "1.5rem" },
														}}
														trashIcon={{
															color: "#5c636a",
															icon: faTrash,
															style: {
																...{
																	fontSize: "1.5rem",
																	display: tasksData.fields.length > 1 ? "block" : "none",
																},
															},
														}}
														editCallback={() => populateTaskToEdit( tasks, index )}
														deleteCallBack={() => checkAndDelete( tasks, index )}
													/>
												</td>
											</tr>
										</tbody>
									)
								} )}
							</Table>
						</Col>
					</Row>
				</FormProvider>
			</Modal.Body>
		</React.Suspense>
	)
}

const TaskCategories = () => {
	const methods = useFormContext<ServiceFormType>()
	const serviceStore = useServiceDialogStore()

	const getCategory: { [key: string]: ReactSelectCreatableCustom[] } = {
		provide: serviceStore.serviceProviderCategories,
		consume: serviceStore.serviceConsumerCategories,
	}

	const createCategory: { [key: string]: serviceStoreCreateCallback } = {
		provide: serviceStore.setServiceProviderCategories,
		consume: serviceStore.setServiceConsumerCategories,
	}

	const updateCategory: { [key: string]: serviceStoreCallback } = {
		provide: serviceStore.updateserviceProviderCategories,
		consume: serviceStore.updateserviceConsumerCategories,
	}

	const [ editValue, setEditValue ] = React.useState<ReactSelectCreatableCustom>( {
		value: "",
		label: "",
		type: "",
		show: "",
	} )

	const createOption = (
		labelName: string,
		categoryFor: TaskCategory.PROVIDER | TaskCategory.OTHERS,
	) => {
		const id = crypto.randomUUID()

		const dataToCheck = getCategory[ categoryFor ].filter(
			( x: ReactSelectCreatableCustom ) => x.label === labelName,
		)

		if ( dataToCheck.length >= 1 ) return

		createCategory[ categoryFor ]( {
			value: id,
			type: categoryFor,
			label: labelName,
		} )
	}

	const categoryToEdit = () => {
		const dataToEdit = getCategory[ editValue.type ].filter(
			( x: ReactSelectCreatableCustom ) => x.value != editValue.value,
		)
		updateCategory[ editValue.type ]( [ ...dataToEdit, editValue ] )
		setEditValue( { value: "", label: "", type: "" } )
	}

	const categoryToDelete = () => {
		if ( getCategory[ editValue.type ] == undefined ) return null
		const getTaskCategoryToDelete: { [key: string]: "consumerCategoryId" | "providerCategoryId" } =
			{
				provide: "providerCategoryId",
				consume: "consumerCategoryId",
			}
		const category = getTaskCategoryToDelete[ editValue.type ]
		const deleteClearance = methods.watch( "tasks" ).filter( ( x ) => x[ category ] === editValue.value )
		if ( deleteClearance.length >= 1 )
			return methods.setError( "tasks", {
				message: "Please update the task first, task is dependant on the this category",
			} )

		const dataToDelete = getCategory[ editValue.type ].filter(
			( x: ReactSelectCreatableCustom ) => x.value != editValue.value,
		)

		updateCategory[ editValue.type ]( dataToDelete )
		setEditValue( { ...editValue, value: "", label: "", type: "" } )
	}

	const editCallback = () => {
		editValue.value.length >= 2 ? setEditValue( { ...editValue, show: editValue.type } ) : null
	}

	return (
		<section style={{ width: "100%", display: "flex" }}>
			<div style={{ width: "50%" }} className="mx-4">
				<span>
					<Form.Label>Manage Provider Categories</Form.Label>
					<span className="d-flex" style={{ width: "100%" }}>
						{editValue.show === TaskCategory.PROVIDER ? (
							<div style={{ width: "100%" }}>
								<CreatableSelectEdit
									value={editValue.label}
									onChange={( e ) => {
										setEditValue( { ...editValue, label: e.currentTarget.value } )
									}}
									autoFocus
									editCallback={categoryToEdit}
									closeCallback={() => setEditValue( { value: "", label: "", type: "" } )}
								/>
							</div>
						) : (
							<section style={{ width: "100%" }} className="d-flex">
								<div style={{ width: "100%" }}>
									<CreatableSelect
										isClearable
										options={serviceStore.serviceProviderCategories}
										onCreateOption={( e ) => createOption( e, TaskCategory.PROVIDER )}
										value={
											editValue.type == TaskCategory.PROVIDER
												? editValue
												: { value: "", label: "", type: "" }
										}
										onChange={( e ) => {
											setEditValue( {
												value: e?.value + "",
												label: e?.label + "",
												type: e?.type + "",
											} )
										}}
									/>
								</div>

								<EditTrash
									editIcon={{
										color: editValue.type === TaskCategory.PROVIDER ? "#8faadc" : "#d9d4d4",
										icon: faPencil,
										style: { fontSize: "1.5rem" },
									}}
									trashIcon={{
										color: editValue.type === TaskCategory.PROVIDER ? "#5c636a" : "#d9d4d4",
										icon: faTrash,
										style: { fontSize: "1.5rem" },
									}}
									editCallback={editCallback}
									deleteCallBack={categoryToDelete}
								/>
							</section>
						)}
					</span>
				</span>
			</div>
			<span className="vr"></span>
			<div style={{ width: "50%" }} className="mx-4">
				<span>
					<Form.Label>Manage Consumer Categories</Form.Label>
					<span className="d-flex" style={{ width: "100%" }}>
						{editValue.show === TaskCategory.OTHERS ? (
							<div style={{ width: "100%" }}>
								<CreatableSelectEdit
									value={editValue.label}
									onChange={( e ) => {
										setEditValue( { ...editValue, label: e.currentTarget.value } )
									}}
									autoFocus
									editCallback={categoryToEdit}
									closeCallback={() => setEditValue( { value: "", label: "", type: "" } )}
								/>
							</div>
						) : (
							<section style={{ width: "100%" }} className="d-flex">
								<div style={{ width: "100%" }}>
									<CreatableSelect
										isClearable
										options={serviceStore.serviceConsumerCategories}
										onCreateOption={( e ) => createOption( e, TaskCategory.OTHERS )}
										value={
											editValue.type == TaskCategory.OTHERS
												? editValue
												: { value: "", label: "", type: "" }
										}
										onChange={( e ) => {
											setEditValue( {
												value: e?.value + "",
												label: e?.label + "",
												type: e?.type + "",
											} )
										}}
									/>
								</div>
								<EditTrash
									editIcon={{
										color: editValue.type === TaskCategory.OTHERS ? "#8faadc" : "#d9d4d4",
										icon: faPencil,
										style: { fontSize: "1.5rem" },
									}}
									trashIcon={{
										color: editValue.type === TaskCategory.OTHERS ? "#5c636a" : "#d9d4d4",
										icon: faTrash,
										style: { fontSize: "1.5rem" },
									}}
									editCallback={editCallback}
									deleteCallBack={categoryToDelete}
								/>
							</section>
						)}
					</span>
				</span>
			</div>
		</section>
	)
}

export const labelFinder = async (
	tasks: labelFinderType,
	id: string,
): Promise<string | undefined> => {
	const findArrayTask = tasks.find( ( task: any ) => task.id === id )

	if ( findArrayTask ) {
		return findArrayTask.name
	}
}

const categorylabelFinder = ( store: ReactSelectCreatableCustom[], id: string ) => {
	const findArrayTask = store.filter( ( task: ReactSelectCreatableCustom ) => task.value == id )
	if ( findArrayTask ) {
		return findArrayTask[ 0 ]?.label
	}
}

type serviceStoreCreateCallback = ( next: ReactSelectCreatableCustom ) => void
type serviceStoreCallback = ( next: ReactSelectCreatableCustom[] ) => void

export default ServiceTaskBox
