import { faCircleDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome"
import "@xyflow/react/dist/style.css"
import Moment from "react-moment"
import { useSearchParams } from "react-router-dom"
import { useFetchTimeline, useFetchTimelineTodo } from "../../services/celebApitTimeline"
import { useCategoryfetchDetails } from "../../services/CelebService.Service"
import { useSidebarStore } from "../../store/sideBarStore"
import { userSessionStore } from "../../store/userSessionStore"
import { downloadFile } from "../../utils/utilFunctions"
import "./graph.css"

const AppGraph = () => {
	const sidebar = useSidebarStore()
	const { user } = userSessionStore()
	const patientCreatetionDate = sidebar.sideBarPatientData.PatientData.createdAt ?? "null"
	const [ queryParam ] = useSearchParams()
	const treatmentid = queryParam.get( "PatientId" )
	const serviceId = sidebar?.sideBarPatientData?.PatientData?.serviceId
	const { data: dataTimeline } = useFetchTimeline( treatmentid ? treatmentid : "" )
	const { data: dataTimelineTodo } = useFetchTimelineTodo( treatmentid ? treatmentid : "" )
	const { data: taskCategories } = useCategoryfetchDetails( serviceId ? serviceId : "" )
	const categoryMapping = ( categoryId: string ) => {
		if ( !user?.isAdmin && categoryId ) {
			const result = taskCategories.find( ( eachCategory: { id: string; name: any } ) => {
				if ( eachCategory?.id == categoryId ) return eachCategory?.name
			} )
			return result?.name
		}
	}

	return (
		<div
			style={{ backgroundColor: "#d9d9d9", width: "100%", padding: "1rem", borderRadius: "11px" }}
		>
			<p style={{ color: "#818181", fontSize: "13px" }}>
				Submitted: <Moment format="MM/DD/YYYY">{patientCreatetionDate}</Moment>
			</p>{" "}
			<div className="timeline" style={{ width: "100%", overflow: "auto", height: "96%" }}>
				{dataTimeline.map( ( x, index ) => {
					return (
						<div className={`tl-item ${x.id ? "active" : ""}`} key={x.id}>
							<div className={`tl-dot ${x.taskId ? " b-warning" : "b-danger"}`}></div>
							<span className="w-100">
								<div className="d-flex justify-content-between w-100">
									<header>
										{user.specialRole == "sales" ? (
											<header>
												{categoryMapping( x.timelineServiceTask.consumerCategoryId ) ??
													x.timelineServiceTask.name.charAt( 0 ).toUpperCase() +
														x.timelineServiceTask.name.slice( 1 )}
											</header>
										) : (
											<header>
												{categoryMapping(
													user.isDoctor
														? x.timelineServiceTask.consumerCategoryId
														: x.timelineServiceTask.providerCategoryId,
												) ??
													x.timelineServiceTask.name.charAt( 0 ).toUpperCase() +
														x.timelineServiceTask.name.slice( 1 )}
											</header>
										)}
										<br />
										<p style={{ color: "#818181", fontSize: "14px" }}>
											{x.timelineActionedBy.firstName + " " + x.timelineActionedBy.lastName}
											<br />
											<b>{x.action == "task_completed" ? "" : x.action}</b>
										</p>
									</header>
									<header style={{ color: "#818181", fontSize: "14px" }}>
										<Moment format="MM/DD/YYYY hh:mma" local>
											{x.createdAt}
										</Moment>
									</header>
								</div>

								<div id="d-flex">
									<p
										hidden={
											x.timelineTreatmentPlanServiceTask.note ||
											x.timelineTreatmentPlanServiceTask.uploadFileUri
												? true
												: false
										}
										style={{ fontStyle: "oblique", color: "#818181", fontSize: "13px" }}
									>
										No Comment or File
									</p>

									<div className="d-flex align-items-center">
										<div
											hidden={!x.timelineTreatmentPlanServiceTask.note}
											style={{
												fontSize: "13px",
												border: "solid",
												padding: "10px",
												borderRadius: "10px",
												borderColor: "whitesmoke",
												borderWidth: "0.5px",
												width: "100%",
												minHeight: "7vh",
												cursor: "text",
											}}
										>
											<span>{x.timelineTreatmentPlanServiceTask.note}</span>
										</div>
									</div>
									{x.timelineTreatmentPlanServiceTask.uploadFileUri && (
										<div
											className="file-item my-2 p-2 border rounded d-flex align-items-center"
											onClick={async () => {
												sidebar.addTimelineDownloadingFile( index )
												const isDone = await downloadFile(
													x.timelineTreatmentPlanServiceTask.uploadFileUri,
													x.timelineTreatmentPlanServiceTask?.uploadFileName,
												)

												isDone && sidebar.removeTimelineDownloadingFile( index )
											}}
										>
											<span className="icon-container">
												{sidebar.downloadingTimelineFileIndexes.includes( index ) ? (
													<div className="spinner-border text-secondary" role="status">
														<span className="sr-only" />
													</div>
												) : (
													<FA
														icon={faCircleDown}
														size="xl"
														className="mx-2 text-end"
														style={{ fontSize: "35px", color: "#8faadc", cursor: "pointer" }}
													/>
												)}
											</span>
											<span className="flex-grow-1">
												<div className="file-name">
													{x.timelineTreatmentPlanServiceTask?.uploadFileName ??
														x.timelineServiceTask.name}
												</div>
											</span>
										</div>
									)}
								</div>
							</span>
						</div>
					)
				} )}

				{dataTimelineTodo.map( ( x, index ) => {
					return (
						<div className={`tl-item ${x.id ? "" : ""}`}>
							<div className={`tl-dot ${index == 0 ? " b-current" : ""}`}></div>
							<div>
								{user.specialRole == "sales" ? (
									<header>
										{categoryMapping( x.consumerCategoryId ) ??
											x.treatmentPlanServiceTask.name.charAt( 0 ).toUpperCase() +
												x.treatmentPlanServiceTask.name.slice( 1 )}
									</header>
								) : (
									<header>
										{categoryMapping( user.isDoctor ? x.consumerCategoryId : x.providerCategoryId ) ??
											x.treatmentPlanServiceTask.name.charAt( 0 ).toUpperCase() +
												x.treatmentPlanServiceTask.name.slice( 1 )}
									</header>
								)}
							</div>
						</div>
					)
				} )}
			</div>
		</div>
	)
}

export default AppGraph
