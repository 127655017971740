import React from "react"
import { Form } from "react-bootstrap"
import { useFormContext } from "react-hook-form"
import { ReplaceOrderSchemaType } from "../../../services/CelebPatient.Service"
import { ToothSelectionComponent } from "../../utlity/quickSelection"
import { PatientOtherForm } from "./PatientFormFields"

const PatientTreatmentInstructionsTab = () => {
	return (
		<React.Suspense>
			<PatientTreatmentInstructionsTabImpl />
		</React.Suspense>
	)
}

const PatientTreatmentInstructionsTabImpl = () => {
	const methods = useFormContext<ReplaceOrderSchemaType>()

	return (
		<React.Fragment>
			<Form>
				{/* upperSection */}
				<div className="d-flex justify-content-left">
					<section>
						<div className="d-flex gap-5">
							<div>
								<p style={{ color: "red", position: "relative" }}>
									{methods.formState.errors.root?.message}
								</p>
								<p style={{ color: "red", position: "relative" }}>
									{methods.formState.errors.replaceOrder?.root?.message}
								</p>
								<div className="d-flex gap-5">
									<p>
										Upper
										<b className="text-danger">*</b>
									</p>
									<Form.Check
										reverse
										{...methods.register( "replaceOrder.upperWires" )}
										label="AIW-1"
										// value="0.018"
										value="AIW-1"
									/>
									<Form.Check
										reverse
										{...methods.register( "replaceOrder.upperWires" )}
										label="AIW-2"
										// value="0.020"
										value="AIW-2"
									/>
									<Form.Check
										reverse
										{...methods.register( "replaceOrder.upperWires" )}
										label="AIW-3"
										// value="0.022"
										value="AIW-3"
									/>
								</div>
								<div className="d-flex gap-5">
									<p>
										Lower
										<b className="text-danger">*</b>
									</p>
									<Form.Check
										reverse
										{...methods.register( "replaceOrder.lowerWires" )}
										label="AIW-1"
										// value="0.018"
										value="AIW-1"
									/>
									<Form.Check
										reverse
										{...methods.register( "replaceOrder.lowerWires" )}
										label="AIW-2"
										// value="0.020"
										value="AIW-2"
									/>
									<Form.Check
										reverse
										{...methods.register( "replaceOrder.lowerWires" )}
										// value="0.022"
										value="AIW-3"
										label="AIW-3"
									/>
								</div>
							</div>
						</div>
					</section>
				</div>

				<div className="d-flex gap-5 w-100 my-2">
					<p style={{ width: "8rem" }} className="align-items-center d-grid">
						Brackets and IDB
					</p>
					<div>
						<ToothSelectionComponent usedFor="teethReplacement" />
					</div>

					<p style={{ color: "red" }}>
						{methods.formState.errors.treatmentPlan?.treatmentFiles?.message}
					</p>
				</div>
				<div className="w-50">
					<PatientOtherForm name="treatmentPlan" required label="Instruction">
						<Form.Control as="textarea" rows={6} {...methods.register( "replaceOrder.note" )} />
						<p className="text-danger">
							{methods.formState.errors.treatmentPlan?.treatmentFiles?.message}
						</p>
					</PatientOtherForm>
				</div>
			</Form>
		</React.Fragment>
	)
}

export default PatientTreatmentInstructionsTab
