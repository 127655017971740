import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import {
	Button,
	Form,
	FormControlProps,
	InputGroup,
	Modal,
	Nav,
	Navbar,
	Spinner,
	Table,
} from "react-bootstrap"
import { FieldErrors, FormProvider, useForm, useFormContext } from "react-hook-form"
import { useLocation } from "react-router"
import LoadingOverlay from "../../../assets/styles/components/loader/loadingOverlay"
import { constants } from "../../../services/CelebApiConstants"
import { CelebMedicalFileService } from "../../../services/CelebMedicalFile.Service"
import {
	ServiceAddress,
	ServiceSchemaForm,
	useCreateServiceMutation,
} from "../../../services/CelebService.Service"
import { useProfileDialogStore } from "../../../store/profileDialogStore"
import { useServiceDialogStore } from "../../../store/serviceDialogStore"
import { EditTrash } from "../../utlity/EditTrash"
import { infoMapper } from "../../utlity/GenricFunctions"
import ProfileAddressAddDialog from "../profile-v2/ProfileAddressAdd.dialog"
import { ServiceFormInput, ServiceFormType } from "./ServiceFormFields"
import ServiceTaskBox from "./ServiceTaskForm"

const ServicesAddDialog = () => {
	const methods = useForm<ServiceFormType>( {
		resolver: zodResolver( ServiceSchemaForm ),
		mode: "all",
	} )
	const serviceStore = useServiceDialogStore()
	const closeService = () => {
		methods.reset()
		serviceStore.setCreateOpen( false )
		serviceStore.resetTaskCategories()
	}

	return (
		<React.Suspense fallback={<LoadingOverlay />}>
			<FormProvider {...methods}>
				<Modal
					show={serviceStore.createOpen}
					onHide={closeService}
					size="xl"
					animation={true}
					backdrop="static"
				>
					<ServiceNavbar />
					<ServiceAddImpl />
				</Modal>
			</FormProvider>
		</React.Suspense>
	)
}

const ServiceAddImpl = () => {
	return (
		<React.Fragment>
			<main className="p-3">
				<TabShowCase />
			</main>
		</React.Fragment>
	)
}

const TabShowCase = () => {
	const location = useLocation()
	switch ( location.hash ) {
		case "#ServiceInfo":
			return <ServiceDetails />
		case "#Tasks":
			return <ServiceTaskBox />
		default:
			return
	}
}

const ServiceNavbar = () => {
	const locationHash = useLocation()
	const serviceStore = useServiceDialogStore()
	const { mutateAsync: creatServiceAsync } = useCreateServiceMutation()
	const { setValue, getValues, reset, handleSubmit, formState, watch } =
		useFormContext<ServiceFormType>()
	const saveService = async ( value: ServiceFormType ) => {
		const logo = watch( "logo" )
		const logoUrl = logo != null ? await uploadLogo( logo ) : ""
		const formDataObj = {
			...value,
			categories: [
				...serviceStore.serviceConsumerCategories,
				...serviceStore.serviceProviderCategories,
			],
			logo: logoUrl,
			address: getValues( "address" ),
		}
		creatServiceAsync( formDataObj ).then( () => closeService() )
	}
	const closeService = () => {
		reset()
		serviceStore.setCreateOpen( false )
		serviceStore.resetTaskCategories()
	}
	const onSubmitError = ( errors2: FieldErrors<ServiceFormType> ) => {
		console.error( { errors2 } )
		setValue( "taskErrors", errors2.tasks?.message )
	}

	return (
		<React.Fragment>
			<Navbar id="navbarScroll" as={Modal.Header}>
				<Nav variant="tabs" defaultActiveKey="#ServiceInfo" className="d-flex mt-2 me-auto">
					<Nav.Link className="" active={locationHash.hash == "#ServiceInfo"} href="#ServiceInfo">
						Create Service
					</Nav.Link>
					<Nav.Link active={locationHash.hash == "#Tasks"} href="#Tasks">
						Tasks
					</Nav.Link>
				</Nav>
				<span>
					<Button type="submit" onClick={handleSubmit( saveService, onSubmitError )}>
						{formState.isSubmitting ? (
							<>
								<Spinner animation="border" role="status" size="sm" /> Saving..{" "}
							</>
						) : (
							"Save"
						)}
					</Button>
					<Button variant="secondary" className="mx-2" onClick={closeService}>
						Cancel
					</Button>
				</span>
			</Navbar>
		</React.Fragment>
	)
}

const ServiceDetails = () => {
	const methods = useFormContext<ServiceFormType>()
	const [ logo, setLogo ] = React.useState<FileList | null>()
	const profileStore = useProfileDialogStore()
	const addresses = methods.watch( "address" )
	const addAdress = ( address: ServiceAddress ) => {
		methods.setValue( "address", address )
	}
	const DeleteAdress = ( index: number ) => {
		return methods.setValue( `address.${index}.deleted`, true )
	}
	return (
		<Modal.Body>
			<FormProvider {...methods}>
				<Form>
					<span className="d-inline-flex justify-content-evenly w-100">
						<ServiceFormInput label="Service Name" autoFocus name="name" />
						<div>
							<Form.Label>Service Logo</Form.Label>
							<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
								<Form.Control
									id="logo-upload"
									type="file"
									accept="image/*"
									onChange={( e: React.ChangeEvent<HTMLInputElement> ) => {
										const files = e.currentTarget?.files
										if ( files && files.length > 0 ) {
											setLogo( files )
											methods.setValue( "logo", files )
										}
									}}
									hidden
								/>
								<label htmlFor="logo-upload">
									<img
										src={
											methods.watch( "logo" )?.[ 0 ]
												? URL.createObjectURL( methods.watch( "logo" )?.[ 0 ] )
												: "/building.png"
										}
										style={{
											width: "2.5rem",
											height: "2.5rem",
											cursor: "pointer",
											borderRadius: "5px",
										}}
										alt="Selected Logo"
										onError={( { currentTarget } ) => {
											currentTarget.onerror = null
											currentTarget.src = constants.IMAGEURL + "building.png"
											console.log( "service logo uri", process.env.REACT_APP_S3BASE )
										}}
									/>
								</label>
							</div>
						</div>
						<ServiceFormInput
							label="Contact No"
							name="contactNo"
							placeholder="Contact No"
							type="number"
						/>
						<ServiceFormInput label="Email" name="email" placeholder="Email" />
						<div>
							<Form.Label>Service Status</Form.Label>
							<Form.Check type="checkbox" {...methods.register( "status" )} label={"Make Active"} />
						</div>
					</span>
					<span className="d-inline-flex justify-content-evenly w-100">
						<ServicePricingSetter
							name="Price Upper"
							formControlAccess={{
								...methods.register( "priceUpper", {
									valueAsNumber: true,
									onChange: ( e ) => {
										methods.setValue( "priceUpper", parseFloat( e.currentTarget.value ) )
									},
								} ),
							}}
						/>
						<ServicePricingSetter
							name="Price Lower"
							formControlAccess={{
								...methods.register( "priceLower", {
									valueAsNumber: true,
									onChange: ( e ) => {
										methods.setValue( "priceLower", parseFloat( e.currentTarget.value ) )
									},
								} ),
							}}
						/>
						<ServicePricingSetter
							name="Price Both"
							formControlAccess={{
								...methods.register( "priceBoth", {
									valueAsNumber: true,
									onChange: ( e ) => {
										methods.setValue( "priceBoth", parseFloat( e.currentTarget.value ) )
									},
								} ),
							}}
						/>
						<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
							<Button
								onClick={() => {
									profileStore.setCreateOpen( true )
								}}
							>
								Add Address
							</Button>
						</div>
					</span>
					<Table striped bordered hover className="my-1">
						<thead>
							<tr>
								<th>Office Name</th>
								<th>Address</th>
								<th>Modify</th>
							</tr>
						</thead>
						<tbody>
							{addresses?.map( ( add, index: number ) => (
								<>
									<tr hidden={add.deleted}>
										<td>{add?.name}</td>
										<td>
											{infoMapper( add, [
												"id",
												"name",
												"deleted",
												"deletedAt",
												"deletedBy",
												"doctorId",
												"category",
												"createdAt",
												"updatedAt",
											] )}
										</td>
										<td className="text-end">
											<EditTrash
												editIcon={{ icon: faPencil, color: "#8faadc" }}
												trashIcon={{ icon: faTrash, color: "#d9d9d9" }}
												deleteCallBack={() => {
													DeleteAdress( index )
												}}
												editCallback={() => {
													profileStore.setEditOpen( add )
												}}
											/>
										</td>
									</tr>
								</>
							) )}
						</tbody>
					</Table>
					<ProfileAddressAddDialog cb={addAdress} />
				</Form>
			</FormProvider>
		</Modal.Body>
	)
}

export const uploadLogo = async ( serviceLogo: FileList | null ) => {
	if ( serviceLogo && serviceLogo?.length >= 1 ) {
		const medicalFileService = CelebMedicalFileService.getInstance()
		const fileUploadResponse = await medicalFileService.getPublicUploadUrls( [ serviceLogo[ 0 ]?.name ] )
		const uploadUrl = fileUploadResponse.data.data[ 0 ].uploadUrl
		const response = await medicalFileService.uploadFileToS3( uploadUrl, serviceLogo[ 0 ] )
		if ( response.status === 200 ) {
			const logo = uploadUrl.split( "?" )[ 0 ].split( "/" )[ 3 ]
			return logo
		}
	}
}

export const ServicePricingSetter = ( {
	name,
	formControlAccess,
}: {
	name: string
	formControlAccess: FormControlProps
} ) => {
	return (
		<React.Fragment>
			<div>
				<span>{name}</span>
				<div className="d-flex justify-content-evenly">
					<InputGroup className="mb-3">
						<InputGroup.Text>$</InputGroup.Text>
						<Form.Control
							type="number"
							aria-label="Amount (to the nearest dollar)"
							{...formControlAccess}
						/>
					</InputGroup>
				</div>
			</div>
		</React.Fragment>
	)
}

export default ServicesAddDialog
