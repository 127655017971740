import { create } from "zustand"
import { TreatmentSchemaType } from "../services/CelebTreatmentTask.Service"

interface TaskCompletionDialog {
	dialogOpen: boolean
	setDialogOpen: ( next: boolean ) => void

	landingPageTasks: TreatmentSchemaType[]
	setLandingPageTasks: ( next: TreatmentSchemaType ) => void

	taskCompleteIds: []
	setCompleteDialog: ( next: [] ) => []

	updateLandingPageTasks: ( next: TreatmentSchemaType[] ) => void

	setPaymentTaskModalOpen: ( next: string | null ) => void
	PaymentTaskModalOpen: null | string

	setPaymentApproval: ( next: boolean ) => void
	paymentApproval: boolean

	reset: () => void
}

export const useTaskCompletionDialogStore = create<TaskCompletionDialog>()( ( set ) => ( {
	dialogOpen: false,
	setDialogOpen: ( next: boolean ) => set( { dialogOpen: next } ),

	landingPageTasks: [],
	setLandingPageTasks: ( next: TreatmentSchemaType ) =>
		set( ( state ) => ( {
			landingPageTasks: [ ...state.landingPageTasks, next ],
		} ) ),

	updateLandingPageTasks: ( next: TreatmentSchemaType[] ) =>
		set( () => ( {
			landingPageTasks: next,
		} ) ),

	taskCompleteIds: [],
	setCompleteDialog: ( next: [] ) => [ ...next ],

	reset: () =>
		set( {
			dialogOpen: false,
			landingPageTasks: [],
			taskCompleteIds: [],
		} ),

	PaymentTaskModalOpen: null,
	setPaymentTaskModalOpen: ( next: string | null ) => set( { PaymentTaskModalOpen: next } ),

	paymentApproval: false,
	setPaymentApproval: ( next: boolean ) => set( { paymentApproval: next } ),
} ) )
