import { Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
const Unathorized = () => {
	const { custom } = useParams()
	return (
		<div>
			{" "}
			<Container className="py-5 text-center">
				<Row>
					<Col>
						<h1 className="display-1">403</h1>
						<h2>You are unathorized to access the page {custom?.toLocaleUpperCase()}</h2>
						<p className="lead">Please Contact Support</p>
						<Link to={"/"}>
							<button className="btn btn-primary">Go Home</button>
						</Link>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default Unathorized
