import { AxiosError } from "axios"
import React from "react"
import { useRouteError } from "react-router-dom"

const ErrorBoundaryPage = () => {
	const errorc: AxiosError<unknown, any> | any = useRouteError()
	// alert( "Error Boundary Error" )

	window.addEventListener( "storage", () => {
		if ( errorc.status === 401 ) {
			window.localStorage.clear()
			window.location.href = "/"
		}
	} )
	React.useEffect( () => {
		if ( errorc.status === 401 ) {
			window.localStorage.clear()
			window.location.href = "/"
		}
	}, [] )

	return <div>ErrorBoundary</div>
}

export default ErrorBoundaryPage
